import { default as _91id_93EjkPkVndEEMeta } from "/workspace/pages/auction/[id].vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91id_93rzd1kcmBhUMeta } from "/workspace/pages/item/[id].vue?macro=true";
export default [
  {
    name: "auction-id",
    path: "/auction/:id()",
    component: () => import("/workspace/pages/auction/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "item-id",
    path: "/item/:id()",
    component: () => import("/workspace/pages/item/[id].vue").then(m => m.default || m)
  }
]